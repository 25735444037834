import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import WAImage from "../images/whatsapp.png"
import TGImage from "../images/telegram.png"


const ContactsPage = () => {
  return(
    <Layout>
      <SEO title="⚡Уралпромкабель — контакты, телефоны, адреса, реквизиты" />
      <main>
        <h1>Контакты</h1>
        <h2>Отдел продаж</h2>
        <p>
          <strong>E-mail: </strong>
          <a href="mailto:mail@upcable.ru">mail@upcable.ru</a>
        </p>
        <p>
          <span>Шестаков Михаил Александрович</span><br/>
          <strong>Телефон: </strong>+7 965 562 9920 <a href="https://wa.me/79655629920"><img src={WAImage}/></a> <a href="tg://resolve?domain=mashestakov"><img src={TGImage}/></a>
        </p>
        <p>
          <span>Шилов Александр Владимирович</span><br/>
          <strong>Телефон: </strong>+7 965 563 8770 <a href="https://wa.me/79655638770"><img src={WAImage}/></a>
        </p><br/>
        <h2>Адреса</h2>
        <p>
          <strong>Офис: </strong>614015, Пермский край, г.&nbsp;Пермь, ул.&nbsp;Монастырская, дом №&nbsp;14, офис&nbsp;416
        </p>
        <p>
          <strong>Юридический адрес:</strong><br/>614015, Пермский край, г.&nbsp;Пермь, ул.&nbsp;Монастырская, дом № 14, офис&nbsp;416
        </p>
        <p>
          <strong>Почтовый адрес:</strong><br/>614015, Пермский край, г.&nbsp;Пермь, ул.&nbsp;Монастырская, дом №&nbsp;14, офис&nbsp;416
        </p>
        <p>
          <strong>Складская база г.&nbsp;Пермь:</strong> ул.&nbsp;Васнецова, 12
        </p>
        <p>
          <strong>Складская база г.&nbsp;Екатеринбург: </strong>ул.&nbsp;Полевая, 76
        </p>
      </main>
    </Layout>
  )
}

export default ContactsPage
